import AOS from "aos";

setTimeout(function () {
	document.getElementsByTagName("html")[0].classList.add("loading-delay");
	if ($(window).width() > 768) {
		$("#InPageMainImage").css({
			"margin-top": $("#Header").outerHeight()
		})
	}
}, 3000);

$(function () {
	//
})
$(window).on('load', function () {
	//
	if ($(window).width() > 768) {
		$("#InPageMainImage").css({
			"margin-top": $("#Header").outerHeight()
		})
	}
	setTimeout(function () {
		if ($(window).width() > 768) {
			$("#InPageMainImage").css({
				"margin-top": $("#Header").outerHeight()
			})
		}
	}, 500);

	// alert(uaObj.deviceName);
	if (uaObj.deviceName == "PC") {
		/*
			$("body").niceScroll({
					scrollspeed: 60, //どのくらい進むか
					mousescrollstep: 50  //スクロールしたあとの余韻のレベル
			});
			*/
	}
	AOS.init({
		offset: 300,
		once: true
	});

	const controller = new ScrollMagic.Controller();
	const ProductionNav_check = $(window).width() - 125;
	// console.log(ProductionNav_check);

	$(".Production--Nav li").each(function (e) {
		let ProductionNav_item = $(this).offset().left + $(this).width() + 20;
		// console.log(e + ":" + ProductionNav_item);
		if (ProductionNav_check <= ProductionNav_item) {
			$(this).addClass("Last");
		}
	})

	$(document).on("click", "#SPNavOpen", function () {
		$(this).addClass("is-open");
		setTimeout(() => {
			/*
									$("#HeaderLogo").css({
											opacity: 1
									});
									*/
			$("#SPNav").addClass("is-open");
		}, 300);
	})
	$(document).on("click", "#SPNavClose", function () {
		$("#SPNav").removeClass("is-open");
		setTimeout(() => {
			/*
									$("#HeaderLogo").css({
											opacity: 0
									});
									*/
			$("#SPNavOpen").removeClass("is-open");
		}, 300);
	})
	$(document).on("click", "#SPNav .SubNav i", function () {
		// console.log($(this).parent("li"));

		$(this).parent().parent("li").toggleClass("Active");
		$(this).parent().parent("li").find(".SPNav--SubBox").slideToggle();
		return false;
	})
	//

	var MarketingListween = new TimelineMax().add(
		TweenMax.fromTo($('#MarketingList .Icon'), 0.5, {
			opacity: 0
		}, {
			opacity: 1
		})
	)
		.add(
			new TimelineMax().staggerFromTo($('#MarketingList .Item'), 0.5, {
				opacity: 0,
				y: 10
			}, {
				opacity: 1,
				y: 0
			}, 0.5))


	var Marketing = new ScrollMagic.Scene({
		triggerElement: '#MarketingList',
		triggerHook: 'onCenter',
		reverse: false
	})
		.setTween(MarketingListween)
		// .addIndicators({ name: 'Marketing' })
		.addTo(controller);

	/*
	*/


	var WorksListween = new TimelineMax().staggerFromTo($('.Works--List .Item'), 0.5, {
		opacity: 0,
		y: 50
	}, {
		opacity: 1,
		y: 0
	}, 0.5);

	WorksListween;
	/*
	if (uaObj.deviceName == "PC") {
			var Works = new ScrollMagic.Scene({
					triggerElement: '.Works--List',
					triggerHook: 'onCenter',
					reverse: false
			})
					.setTween(WorksListween)
					// .addIndicators({ name: 'Works' })
					.addTo(controller);
	} else {
			WorksListween;
	}
	*/
	/*
	*/
	new TimelineMax().staggerFromTo($('.About'), 1, {
		opacity: 0,
		y: 100
	}, {
		opacity: 1,
		y: 0
	}, 0.5);


	/*
	*/


	$(document).on("click", ".Marketing--List a", function () {
		// console.log($(this).attr("href"));
		const html = $($(this).attr("href")).html();
		$("body").append('<div class="Marketing--Overlay"></div><div class="Marketing--Popup"><p class="Close"><i class="far fa-times-circle"></i></p>' + html + '</div>');
		$(".Marketing--Popup").fadeIn();
		$(".Marketing--Overlay").fadeIn();
		return false;
	});
	$(document).on("click", ".Marketing--Popup .Close", function () {
		$(".Marketing--Popup").fadeOut(function () {
			$(".Marketing--Popup").remove();
		});
		$(".Marketing--Overlay").fadeOut(function () {
			$(".Marketing--Overlay").remove();
		});
	});
});



$(window).scroll(function () {
	if ($(window).scrollTop() >= ($("#InPageMainImage").outerHeight() / 3) && uaObj.deviceName != "PC") {

		$("#HeaderLogo").css({
			opacity: 0
		});
	} else {
		$("#HeaderLogo").css({
			opacity: 1
		});
	}
});
