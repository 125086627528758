const moment = require('moment');
import Swiper from "swiper";
import lottie from "lottie-web";
/*
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Back } from 'gsap';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
*/
//import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug//.addIndicators';
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */

const controller = new ScrollMagic.Controller()
if ($("#Top").length) {
    $(window).on("load", function () {
        /*
                var video_movie = $("video");
                var video_id = document.getElementById('video');

                video_movie.attr("src", "assets/img/top.mp4");
                video_body = video_movie.get(0);
                video_body.load();

                //動画の読み込みが完了した
                video_id.addEventListener("canplaythrough", function () {
                    video_body.currentTime = 0
                    video_body.play();
                });
        */

        const anim = lottie.loadAnimation({
            container: document.getElementById('kflogo'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: 'assets/img/kflogo.json'
        });
        var ProductionNav1_arg = new Array();
        $("#ProductionNav li:nth-child(3n - 2)").each(function (i) {
            var i = 0;
            ProductionNav1_arg.push($(this).outerWidth());
        });
        var ProductionNav2_arg = new Array();
        $("#ProductionNav li:nth-child(3n - 1)").each(function (i) {
            var i = 0;
            ProductionNav2_arg.push($(this).outerWidth());
        });
        $("#ProductionNav li:nth-child(3n - 2)").each(function (i) {
            $(this).css({
                width: Math.max.apply(null, ProductionNav1_arg) + "px"
            })
        });
        $("#ProductionNav li:nth-child(3n - 1)").each(function (i) {
            $(this).css({
                width: Math.max.apply(null, ProductionNav2_arg) + "px"
            })
        });
        /*
        $("#ProductionNav li:nth-child(3n - 1) i").each(function (i) {
            $(this).css({
                width: $("#ProductionNav li").width() - Math.max.apply(null, ProductionNav2_arg)
            })
        });
        $("#ProductionNav li:nth-child(3n - 2) i").each(function (i) {
            $(this).css({
                width: $("#ProductionNav li").width() - Math.max.apply(null, ProductionNav2_arg)
            })
        });
        */
    })



    $(function () {
        if ($("#Top").length) {
            // console.log(moment().format("YYYY-MM-DD"));

            if (uaObj.deviceName == "PC") {
                const swiper = new Swiper('.swiper-container', {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    // slidesPerView: 3,
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            } else {
                const swiper = new Swiper('.swiper-container', {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    // slidesPerView: 3,
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            }
            //
            const arr = [];
            $('.TopPage--MainImage .Anime').each(function (index) {
                arr.push(index);
            });
            arr.sort(function () {
                return Math.random() - Math.random();
            });
            for (let i = 0; i < arr.length; i++) {

                // console.log(arr[i]);
                setTimeout(function () {
                    $('.TopPage--MainImage .Text' + (arr[i] + 1)).addClass('on');
                }, 50 * i);
            }
            /*
            $('.TopPage--MainImage .Anime').delay(600).queue(function () {
                $(this).addClass('on');
            });
            */
        }
    });
    let Fixed = false;
    $(window).scroll(function () {
        if ($("#Top").length) {

            if ($(window).scrollTop() >= $(window).height() && $(window).width() >= 768) {
                // console.log($(window).width());
                if (!Fixed) {

                    $("#Header").css({
                        top: $("#Header").outerHeight() * -1
                    });
                    setTimeout(() => {
                        $("#Header").addClass("Fixed").removeAttr('style');
                    }, 300);
                    Fixed = true;
                }
            } else {
                if (Fixed) {
                    $("#Header").css({
                        top: $("#Header").outerHeight() * -1
                    });
                    setTimeout(() => {
                        $("#Header").removeClass("Fixed").removeAttr('style');
                    }, 300);
                    Fixed = false;
                }
            }
            if ($(window).scrollTop() >= ($(window).height() / 3) && $(window).width() <= 768) {

                $("#HeaderLogo").css({
                    opacity: 0
                });
            } else {
                $("#HeaderLogo").css({
                    opacity: 1
                });
            }
        }
    })


    //
    /*

    */
    const Productiontext = $(".Production_Title").text();
    let ProductionarrReceive = [];
    ProductionarrReceive = Productiontext.split('');
    const ProductionarrLength = ProductionarrReceive.length;

    $(".Production_Title").text('');
    for (var i = 0; i < ProductionarrLength; i++) {
        ProductionarrReceive[i] = '<span class="production-animate">' + ProductionarrReceive[i] + '</span>';
        $(".Production_Title").append(ProductionarrReceive[i]);
    }
    // var tl = new TimelineMax();
    var Productiontween = new TimelineMax().add([
        new TimelineMax().staggerFromTo(".production-animate", 0.7, {
            opacity: 0,
            x: 30, //100
            z: -1,
            ease: Back.easeIn.config(2)
        }, {
            opacity: 1,
            x: 0,
            z: 0
        }, 0.1),

        TweenMax.fromTo(".TopPage--Production__Nav", 1.5, { y: 100, opacity: 0 }, { y: 0, opacity: 1, ease: Back.easeOut.config(2) })
    ]);
    var Production = new ScrollMagic.Scene({
        triggerElement: '#Production',
        triggerHook: 'onCenter',
        offset: -200,
        reverse: false
    })
        .setTween(Productiontween)
        //.addIndicators({ name: 'Production' })
        .addTo(controller);


    /*
    @ Marketing
    */
    const Marketingtext = $(".Marketing_Title").text();
    let MarketingarrReceive = [];
    MarketingarrReceive = Marketingtext.split('');
    const MarketingarrLength = MarketingarrReceive.length;

    $(".Marketing_Title").text('');
    for (var i = 0; i < MarketingarrLength; i++) {
        MarketingarrReceive[i] = '<span class="marketing-animate">' + MarketingarrReceive[i] + '</span>';
        $(".Marketing_Title").append(MarketingarrReceive[i]);
    }
    // var tl = new TimelineMax();
    var Marketingtween = new TimelineMax().add([
        new TimelineMax().staggerFromTo(".marketing-animate", 0.7, {
            opacity: 0,
            x: 30,
            z: -1,
            ease: Back.easeIn.config(2)
        }, {
            opacity: 1,
            x: 0,
            z: 0
        }, 0.1),
        TweenMax.fromTo(".TopPage--Marketing .Text", 1.5, { x: 30, opacity: 0 }, { x: 0, opacity: 1, delay: 0.5, ease: Back.easeOut.config(2) }),
        TweenMax.fromTo(".TopPage--Marketing .Photo", 1.5, { x: -100, opacity: 0 }, { x: 0, opacity: 1, delay: 0.5, ease: Power2.easeOut })
    ]);
    var Marketing = new ScrollMagic.Scene({
        triggerElement: '#Marketing',
        triggerHook: 'onCenter',
        offset: -200,
        reverse: false
    })
        .setTween(Marketingtween)
        //.addIndicators({ name: 'Marketing' })
        .addTo(controller);


    /*
    @ Works
    */
    const Workstext = $(".Works_Title").text();
    let WorksarrReceive = [];
    WorksarrReceive = Workstext.split('');
    const WorksarrLength = WorksarrReceive.length;

    $(".Works_Title").text('');
    for (var i = 0; i < WorksarrLength; i++) {
        WorksarrReceive[i] = '<span class="Works-animate">' + WorksarrReceive[i] + '</span>';
        $(".Works_Title").append(WorksarrReceive[i]);
    }
    // var tl = new TimelineMax();
    var Workstween = new TimelineMax().add([
        new TimelineMax().staggerFromTo(".Works-animate", 0.7, {
            opacity: 0,
            x: 30,
            z: -1,
            ease: Back.easeIn.config(2)
        }, {
            opacity: 1,
            x: 0,
            z: 0
        }, 0.1),
        TweenMax.fromTo(".TopPage--Works .Works", 1.5, { x: 30, opacity: 0 }, { x: 0, opacity: 1, delay: 0.5, ease: Power2.easeOut })
    ]);
    var Works = new ScrollMagic.Scene({
        triggerElement: '#Works',
        triggerHook: 'onCenter',
        offset: -200,
        reverse: false
    })
        .setTween(Workstween)
        //.addIndicators({ name: 'Works' })
        .addTo(controller);

}


/*
@ Footer
*/
/*
var Footertween = new TimelineMax().add([
    new TimelineMax().staggerFromTo($('#Footer .Footer--Contact p'), 1, {
        y: 100,
        opacity: 0
    }, {
        y: 0,
        opacity: 1
    }, 1),

    TweenMax.fromTo($('#Footer .Footer--About'), 2, {
        y: 50,
        opacity: 0
    }, {
        y: 0,
        opacity: 1
    })
])
var Footer = new ScrollMagic.Scene({
    triggerElement: '#Footer',
    triggerHook: 'onEnter',
    offset: 200,
    duration: 100,
    reverse: true
})
    .setTween(Footertween)
    // .addIndicators({ name: 'Footer' })
    .addTo(controller);
*/
